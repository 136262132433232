<template>
  <div class="container p-0">
    <div
      class="d-flex position-relative"
      :class="
        $can({ key: 'clients', expectedPermission: 'edit' })
          ? 'justify-content-between'
          : 'justify-content-end'
      "
    >
      <button
        v-if="
          user &&
          (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
          $can({ key: 'clients', expectedPermission: 'edit' })
        "
        @click="createNote"
        class="btn btn-outline-blue mr-4"
      >
        <icon type="plus" />Add Form
      </button>
      <button
        v-if="!isLoading && clinicalNotes.length > 0"
        @click="printSelected"
        :disabled="!selectedNotes.length"
        class="btn btn-danger mr-4"
      >
        <icon type="print" />Print Selected
      </button>
      <div
        class="btn-group print-settings-group"
        v-if="!isLoading && clinicalNotes.length > 0"
      >
        <button
          type="button"
          class="btn px-1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-cog"></i>
        </button>
        <div class="dropdown-menu print-menu" @click="$event.stopPropagation()">
          <h3 class="mx-3 my-2">
            <i class="fas fa-cog mr-2"></i>Print settings
          </h3>
          <div class="dropdown-divider"></div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_second_logo_in_report"
                v-model="printSettings.show_second_logo_in_report"
              />
              <label
                class="custom-control-label"
                for="show_second_logo_in_report"
                >Second Logo</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_name_in_report"
                v-model="printSettings.show_name_in_report"
              />
              <label class="custom-control-label" for="show_name_in_report"
                >Name</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_email_in_report"
                v-model="printSettings.show_email_in_report"
              />
              <label class="custom-control-label" for="show_email_in_report"
                >Email</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_location_in_report"
                v-model="printSettings.show_location_in_report"
              />
              <label class="custom-control-label" for="show_location_in_report"
                >Location</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_phone_number_in_report"
                v-model="printSettings.show_phone_number_in_report"
              />
              <label
                class="custom-control-label"
                for="show_phone_number_in_report"
                >Phone Number</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_claim_number_in_report"
                v-model="printSettings.show_claim_number_in_report"
              />
              <label
                class="custom-control-label"
                for="show_claim_number_in_report"
                >Claim Number</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_date_of_loss_in_report"
                v-model="printSettings.show_date_of_loss_in_report"
              />
              <label
                class="custom-control-label"
                for="show_date_of_loss_in_report"
                >Date of Loss</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_date_of_birth_in_report"
                v-model="printSettings.show_date_of_birth_in_report"
              />
              <label
                class="custom-control-label"
                for="show_date_of_birth_in_report"
                >Date of Birth</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_insurer_in_report"
                v-model="printSettings.show_insurer_in_report"
              />
              <label class="custom-control-label" for="show_insurer_in_report"
                >Insurer</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_funding_source_in_report"
                v-model="printSettings.show_funding_source_in_report"
              />
              <label
                class="custom-control-label"
                for="show_funding_source_in_report"
                >Fund Source</label
              >
            </div>
          </div>
          <div class="dropdown-item">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_lawyer_in_report"
                v-model="printSettings.show_lawyer_in_report"
              />
              <label class="custom-control-label" for="show_lawyer_in_report"
                >lawyer</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <alert class="mt-4 mb-4 light-shadow" />
    </div>
    <div
      class="
        card
        shadow-card
        mt-3
        mb-4
        mr-sm-4
        table-container
        border-0
        mobile-tablet-no-card
      "
      v-if="!isLoading && clinicalNotes.length > 0"
    >
      <div class="mobile-tablet-only mt-4 ml-1">
        <label>
          <input
            class="pointer-cursor"
            type="checkbox"
            v-model="selectAll"
            @change="selectDeselectAll"
          />
          Select all to print.
        </label>
      </div>
      <table
        class="
          table table-striped
          mb-0
          responsive-mobile-tablet-table
          border border-lg-0
          p-2 p-md-0
        "
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Created By</th>
            <th>Session Date</th>
            <th>
              <button
                class="btn btn-sm btn-white mr-1"
                @click="toggleReverse"
                :title="!reverseNotesOrder ? 'Sort asc' : 'Sort desc'"
              >
                <i
                  class="fa fa-lg"
                  :class="
                    !reverseNotesOrder
                      ? 'fa-sort-amount-up-alt'
                      : 'fa-sort-amount-down'
                  "
                ></i>
              </button>
              Created Date
            </th>
            <th>Updated Date</th>
            <th>status</th>
            <th class="text-center">Action</th>
            <th>
              <input
                class="pointer-cursor"
                type="checkbox"
                v-model="selectAll"
                @change="selectDeselectAll"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in sortedClinicalNotes" :key="t.id">
            <td><b class="mobile-tablet-only mr-2">Title:</b>{{ t.name }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">Created By:</b
              >{{ t.authorName || "" }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Session Date:</b
              >{{ t.session_date | date("YYYY-MM-DD") }}
              <span v-if="!t.session_date">N/A</span>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created Date:</b
              >{{ t.created_at | date("YYYY-MM-DD") }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Updated Date:</b
              >{{ t.updated_at | date("YYYY-MM-DD") }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b
              >{{ statusOptions[t.status] }}
            </td>
            <td class="text-right td-actions">
              <router-link
                v-if="
                  $can({ key: 'clients', expectedPermission: 'edit' }) &&
                  !t.locked
                "
                :to="`/clients/${patientId}/notes/${t.id}`"
                class="mr-2 btn btn-sm btn-black"
                ><icon type="edit" />
              </router-link>
              <router-link
                :to="`/clients/${patientId}/notes/${t.id}?preview=true`"
                class="ml-1 mr-2 btn btn-sm btn-theme"
                ><icon type="search" />
              </router-link>
              <button
                v-if="user.isAdmin || user.isManagingAdmin"
                class="btn btn-sm btn-black ml-1 mr-2"
                title="Copy Shareable Link"
                @click="copyShareableLink(t)"
                :disabled="generateShareableLinkLoading == t.id"
              >
                <icon :type="shareableLinkButtonIcon(t.id)" />
              </button>
              <button
                v-if="user.isAdmin || user.isManagingAdmin"
                @click="toggleLockStatus(t)"
                :disabled="t.isLocking"
                class="btn btn-sm ml-1 mr-2 px-2"
                :class="t.locked ? 'btn-warning' : 'btn-deep-success'"
              >
                <icon
                  :type="
                    t.isLocking
                      ? 'circle-notch fa-spin'
                      : t.locked
                      ? 'lock'
                      : 'unlock-alt'
                  "
                />
              </button>
              <button
                v-if="
                  (user.isAdmin || user.isManagingAdmin) &&
                  $can({ key: 'clients', expectedPermission: 'edit' })
                "
                @click="deleteNote(t)"
                :disabled="t.isDeleting"
                class="btn btn-sm btn-danger ml-1 mr-2"
              >
                <icon
                  :type="t.isDeleting ? 'spin' : 'trash'"
                  class="fa-circle-notch"
                />
              </button>
            </td>
            <td>
              <input
                class="pointer-cursor"
                type="checkbox"
                :id="`check_${t.id}_id`"
                v-model="t.selected"
                @change="checkSelectedNotes"
              />
              <label
                :for="`check_${t.id}_id`"
                class="mobile-tablet-only ml-2 pointer-cursor"
                >Select to print</label
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <alert
      v-if="!isLoading && clinicalNotes.length == 0"
      class="mt-4 mb-4 mr-4 light-shadow"
      >No clinical notes to show</alert
    >
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "clinicalNotes",
  data() {
    return {
      printSettings: {},
      statusOptions: ["Incomplete/Draft", "Completed"],
      selectAll: false,
      selectedNotes: [],
      reverseNotesOrder: true,
      shareableLinkCopied: false,
    };
  },
  computed: {
    ...mapState({
      clinicalNotes: (state) => state.templates.clinicalNotes,
      isLoading: (state) => state.templates.clinicalLoading,
      generateShareableLinkLoading: (state) =>
        state.templates.generateShareableLinkLoading,
      user: (state) => state.auth.user,
    }),
    patientId: function () {
      return this.$route.params.id;
    },
    sortedClinicalNotes: function () {
      if (!this.reverseNotesOrder) {
        return this.clinicalNotes;
      }
      return new Array(...this.clinicalNotes).reverse();
    },
    shareableLinkButtonIcon: function () {
      return function (id) {
        if (this.generateShareableLinkLoading == id) {
          return "spinner fa-spin";
        }
        if (this.shareableLinkCopied == id) {
          return "check";
        }
        return "link";
      };
    },
  },
  async mounted() {
    this.getClinicalNotes(this.$route.params.id);
  },
  created() {
    this.getCompanyProfile().then((companyData) => {
      if (companyData) {
        this.printSettings = {
          show_name_in_report: companyData.show_name_in_report,
          show_email_in_report: companyData.show_email_in_report,
          show_claim_number_in_report: companyData.show_claim_number_in_report,
          show_date_of_loss_in_report: companyData.show_date_of_loss_in_report,
          show_date_of_birth_in_report:
            companyData.show_date_of_birth_in_report,
          show_insurer_in_report: companyData.show_insurer_in_report,
          show_lawyer_in_report: companyData.show_lawyer_in_report,
          show_location_in_report: companyData.show_location_in_report,
          show_phone_number_in_report: companyData.show_phone_number_in_report,
          show_funding_source_in_report:
            companyData.show_funding_source_in_report,
        };
      }
    });
  },
  methods: {
    ...mapActions({
      getClinicalNotes: "templates/getBasicClinicalNotesByPatientId",
      deleteClinicalNote: "templates/deleteClinicalNote",
      toggleNoteLockStatus: "templates/toggleNoteLockStatus",
      generateShareableLink: "templates/generateShareableLink",
      getCompanyProfile: "settings/getCompanyProfile",
    }),
    selectDeselectAll: function () {
      this.clinicalNotes.forEach((note) => (note.selected = this.selectAll));
      this.checkSelectedNotes();
    },
    checkSelectedNotes: function () {
      const notes = [];
      this.clinicalNotes.forEach((note) => {
        if (note.selected) {
          notes.push(note.id);
        }
      });
      if (notes.length == this.clinicalNotes.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      this.selectedNotes = notes;
    },
    printSelected: function () {
      const routeData = this.$router.resolve({
        name: "patients.notes.print",
        query: { notes: this.selectedNotes, p: this.printSettings },
      });
      window.open(
        routeData.href,
        "targetWindow",
        `toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, fullscreen=yes`
      );
    },
    createNote: function () {
      this.$router.push({
        name: "patients.notes.create",
        params: {
          userId: this.user.id,
          id: this.$route.params.id,
          appointmentId: -1,
        },
      });
    },
    editNote: function (id) {
      this.$router.push({
        name: "patients.notes.edit",
        params: { noteId: id },
      });
    },
    deleteNote: function (task) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            task.isDeleting = true;
            vm.$forceUpdate();
            vm.deleteClinicalNote(task.id).then((deleted) => {
              task.isDeleting = false;
              this.$forceUpdate();
              if (deleted) {
                this.getClinicalNotes(this.$route.params.id);
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Clinical note has been deleted.",
                  "success"
                );
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
    toggleLockStatus: function (task) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You are going to ${task.locked ? "unlock" : "lock"} this note`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${task.locked ? "unlock" : "lock"} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            task.isLocking = true;
            vm.$forceUpdate();
            vm.toggleNoteLockStatus(task).then((success) => {
              task.isLocking = false;
              this.$forceUpdate();
              if (success) {
                task.locked = !task.locked;
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
    toggleReverse: function () {
      this.reverseNotesOrder = !this.reverseNotesOrder;
    },
    copyShareableLink: async function (note) {
      const { token } = await this.generateShareableLink({
        id: note.id,
      });
      const vm = this;
      const urlToCopy = `${window.location.origin}/clinical-notes/${token}`;
      vm.shareableLinkCopied = note.id;
      navigator.clipboard.writeText(urlToCopy).then(
        function () {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
            title: "The shareable link has been copied to your clipboard.",
          });
          setTimeout(() => {
            vm.shareableLinkCopied = false;
          }, 700);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
<style scoped>
.print-settings-group {
  position: absolute;
  right: 0;
}
.print-menu {
  max-height: 300px;
  overflow-y: auto;
}
.btn-white {
  background-color: transparent;
  color: white;
  border: 0;
}
</style>
